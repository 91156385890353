<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-card-title class="m-0 p-0">Course Wise Sales Over View</b-card-title>
      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="align-middle text-center">Sl</th>
              <th>Name</th>
              <th class="text-center align-middle">Total Sales</th>
              <th class="text-center align-middle">Paid Sales</th>
              <th class="text-center align-middle">Total Payment</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(course, index) in courses.data"
              :key="index"
              v-if="courses.data.length"
            >
              <td class="align-middle text-center">
                {{ courses.from + index }}
              </td>
              <th>
                {{ course.name }}
              </th>
              <td class="align-middle text-center">
                {{ course.total_purchase || 0 }}
              </td>
              <td class="align-middle text-center">
                {{ course.total_paid_purchase || 0 }}
              </td>
              <td class="align-middle text-center">
                {{ course.total_payment || 0 }}/-
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ courses.from ? courses.from : 0 }} to
              {{ courses.to ? courses.to : 0 }} of
              {{ courses.total ? courses.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="courses"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  props: ["search"],
  data() {
    return {
      total_sales: 0,
      total_commission: 0,
      premium_sales: 0,
      courses: {},
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    // Get All Role Method
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.$Loading.start();
      const courses = await this.callApi(
        "post",
        "/app/dashboard/course/wise/purchase/report?page=" + this.search.page,
        this.search
      );
      if (courses.status == 200) {
        this.courses = courses.data.courses;
        this.$Loading.finish();
      }
    },
    SearchData: _.debounce(function () {
      this.getResults();
    }, 1000),
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
  },
};
</script>

<style></style>
