<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-body>
        <b-row>
          <b-col class="p-1">
            <!-- ৳  -->
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{ total_purchase }}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="p-1">
            <!-- ৳  -->
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="ShoppingBagIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ total_paid_purchase }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Paid Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="p-1">
            <!-- ৳  -->
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ total_course_purchase }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Course Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="p-1">
            <!-- ৳  -->
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="ArchiveIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ total_course_paid_purchase }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Paid Course Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="p-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ total_purchase_payment }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Payment
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  props: ["search"],
  data() {
    return {
      total_purchase: 0,
      total_paid_purchase: 0,
      total_course_purchase: 0,
      total_course_paid_purchase: 0,
      total_purchase_payment: 0,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const purchase = await this.callApi(
        "post",
        "/app/dashboard/purchase/report",
        this.search
      );
      if (purchase.status == 200) {
        this.total_course_paid_purchase =
          purchase.data.total_course_paid_purchase;
        this.total_course_purchase = purchase.data.total_course_purchase;
        this.total_paid_purchase = purchase.data.total_paid_purchase;
        this.total_purchase = purchase.data.total_purchase;
        this.total_purchase_payment = purchase.data.total_purchase_payment;
      }
    },
    SearchData: _.debounce(function () {
      this.getResults();
    }, 1000),
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
  },
};
</script>

<style></style>
